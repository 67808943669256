<template>
    <div id="sidebar"
         class="w-0 lg:w-72 fixed lg:block left-0 top-15 z-30 whitespace-nowrap transition-all duration-500 ease-in-out h-screen min-h-screen text-slate-400 bg-slate-900 overflow-auto">
        <div class="p-5">
            <!--        dashboard-->
            <SidebarMenuItemLink icon="dashboard" name="Dashboard" prefixes="['dashboard']" to="/dashboard"
                                 @redirect="openSidebarDrop('')"/>

            <div :class="sbarMenu=='user-management'? 'flex items-center py-3 px-4 cursor-pointer relative bg-gray-600 rounded' : 'flex items-center py-3 px-4 cursor-pointer relative hover:bg-gray-600 rounded'"
                 @click="openSidebarDrop('user-management')">
                <img alt="image" class="w-5 h-5" src="@/assets/images/user.svg">
                <p class="pl-2">Access Control</p>
                <svg :class="sbarMenu=='user-management'? 'rotate-180':''" class="w-5 h-5 absolute right-6 duration-300"
                     fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <transition name="menuAccordion"
                        @enter="enter"
                        @leave="leave"
                        @after-enter="afterEnter"
            >
                <!-- User Management menu links -->
                <div v-show="sbarMenu=='user-management'" class="pl-4 overflow-hidden">

                    <SidebarMenuItemLink icon="user" name="Users" prefixes="['create-user','update-user','view-user','list-user']"
                                         to="/list-user"/>

                    <SidebarMenuItemLink icon="check" name="Roles" prefixes="['create-role','update-role','view-role','list-role']"
                                         to="/list-role"/>
                </div>
            </transition>

            <!--        anzsco-->
            <div :class="sbarMenu=='anzsco'? 'flex items-center py-3 px-4 cursor-pointer relative bg-gray-600 rounded' : 'flex items-center py-3 px-4 cursor-pointer relative hover:bg-gray-600 rounded'"
                 @click="openSidebarDrop('anzsco')">
                <img alt="image" class="w-5 h-5" src="@/assets/images/anzsco.svg">
                <p class="pl-2">Occupation</p>
                <svg :class="sbarMenu=='anzsco'? 'rotate-180':''" class="w-5 h-5 absolute right-6 duration-300"
                     fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <!--        anzsco menu item-->
            <transition name="menuAccordion"
                        @enter="enter"
                        @leave="leave"
                        @after-enter="afterEnter"
            >
                <div v-show="sbarMenu=='anzsco'" class="pl-4 overflow-hidden">
                    <SidebarMenuItemLink icon="list" name="Group" prefixes="['group-create','group-update','group-list','group-view']"
                                         to="/group-list"/>

                    <SidebarMenuItemLink icon="occupation" name="Occupation" prefixes="['occupation-create','occupation-update','occupation-list','occupation-view']"
                                         to="/occupation-list"/>
                    <!--        lin-->
                    <SidebarMenuItemLink icon="lin" name="Lin" prefixes="['lin-create','lin-update','lin-view','lin-list']"
                                         to="/lin-list"/>

                    <!--        lin-occupation-->
                    <SidebarMenuItemLink icon="occupation" name="Lin vs Occupation" prefixes="['lin-occupation','lin-occupation-view']"
                                         to="/lin-occupation"/>

                    <!--        Assessing auth list-->
                    <SidebarMenuItemLink icon="check" name="Assessing Auth" prefixes="['assessing-authority','assessing-authority-view']"
                                         to="/assessing-authority"/>

                    <!--        occupation assessing authorities-->
                    <SidebarMenuItemLink icon="auth-occupation" name="Auth vs Occupation" prefixes="['occupation-authority']"
                                         to="/occupation-authority"/>

                    <!--        caveat-->
                    <SidebarMenuItemLink icon="caveat" name="Caveat" prefixes="['caveat','caveat-view']" to="/caveat"/>
                </div>
            </transition>

            <!-- User Management -->

            <!-- visa -->
            <div :class="sbarMenu=='visa'? 'flex items-center py-3 px-4 cursor-pointer relative bg-gray-600 rounded' : 'flex items-center py-3 px-4 cursor-pointer relative hover:bg-gray-600 rounded'"
                 @click="openSidebarDrop('visa')">
                <img alt="image" class="w-5 h-5" src="@/assets/images/visa.svg">
                <p class="pl-2">Visa</p>
                <svg :class="sbarMenu=='visa'? 'rotate-180':''" class="w-5 h-5 absolute right-6 duration-300"
                     fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <!--        visa item link-->
            <transition name="menuAccordion"
                        @enter="enter"
                        @leave="leave"
                        @after-enter="afterEnter"
            >
                <div v-show="sbarMenu=='visa'" class="pl-4 overflow-hidden">
                    <SidebarMenuItemLink icon="occupation" name="Category" prefixes="['visa-category-create','visa-category-update','visa-category-list','visa-category-view']"
                                         to="/visa-category-list"/>

                    <SidebarMenuItemLink icon="ticket" name="Type" prefixes="['visa-type-create','visa-type-update','visa-type-list','visa-type-view']"
                                         to="/visa-type-list"/>

                    <SidebarMenuItemLink icon="visa2" name="Visa" prefixes="['visa-list','visa-view','visa-create','visa-update']"
                                         to="/visa-list"/>

                    <SidebarMenuItemLink icon="pathway" name="Visa Pathway" prefixes="['visa-pathway']"
                                         to="/visa-pathway"/>

                    <SidebarMenuItemLink icon="stream" name="Visa Stream" prefixes="['visa-stream-create','visa-stream-update','visa-stream-list','visa-stream-view']"
                                         to="/visa-stream-list"/>

                    <SidebarMenuItemLink icon="lin-stream" name="Lin Stream" prefixes="['visa-lin-stream-list']"
                                         to="/lin-visa-stream-list"/>

                    <SidebarMenuItemLink icon="schedule" name="Visa Schedule" prefixes="['visa-schedule-create','visa-schedule-update','visa-schedule-list','visa-schedule-view']"
                                         to="/visa-schedule-list"/>

                    <SidebarMenuItemLink icon="condition" name="Visa Condition" prefixes="['visa-condition','visa-condition-view']"
                                         to="/visa-condition"/>

                    <SidebarMenuItemLink icon="question" name="Visa Requirement" prefixes="['create-visa-requirement','update-visa-requirement','view-visa-requirement','visa-requirement-list']"
                                         to="/visa-requirement-list"/>
                </div>
            </transition>

            <!--        state-->
            <SidebarMenuItemLink icon="state" name="State" prefixes="['state','state-view']" to="/state"
                                 @redirect="openSidebarDrop('')"/>

            <!-- Frontend manage -->
            <div :class="sbarMenu=='frontend-manage'? 'flex items-center py-3 px-4 cursor-pointer relative bg-gray-600 rounded' : 'flex items-center py-3 px-4 cursor-pointer relative hover:bg-gray-600 rounded'"
                 @click="openSidebarDrop('frontend-manage')">
                <img alt="image" class="w-5 h-5" src="@/assets/images/commandline.svg">
                <p class="pl-2">Public site</p>
                <svg :class="sbarMenu=='frontend-manage'? 'rotate-180':''" class="w-5 h-5 absolute right-6 duration-300"
                     fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <transition name="menuAccordion"
                        @enter="enter"
                        @leave="leave"
                        @after-enter="afterEnter"
            >
                <!--            frontend menu link-->
                <div v-show="sbarMenu=='frontend-manage'" class="pl-4 overflow-hidden">

                    <SidebarMenuItemLink icon="bar" name="Menu" prefixes="['create-menu','update-menu','view-menu','menu-list','create-menu-items','update-menu-items','view-menu-items','menu-items-list']"
                                         to="/menu-list"/>

                    <SidebarMenuItemLink icon="bar" name="Page" prefixes="['create-page','update-page','view-page','page-list']"
                                         to="/page-list"/>
                </div>
            </transition>

<!--            subscription manage-->
            <!-- Subscription manage -->
            <div :class="sbarMenu=='subscription-manage'? 'flex items-center py-3 px-4 cursor-pointer relative bg-gray-600 rounded' : 'flex items-center py-3 px-4 cursor-pointer relative hover:bg-gray-600 rounded'"
                 @click="openSidebarDrop('subscription-manage')">
                <img alt="image" class="w-5 h-5" src="@/assets/images/commandline.svg">
                <p class="pl-2">Subscription</p>
                <svg :class="sbarMenu=='subscription-manage'? 'rotate-180':''" class="w-5 h-5 absolute right-6 duration-300"
                     fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <transition name="menuAccordion"
                        @enter="enter"
                        @leave="leave"
                        @after-enter="afterEnter"
            >
                <!--            Subscription menu link-->
                <div v-show="sbarMenu=='subscription-manage'" class="pl-4 overflow-hidden">

                    <SidebarMenuItemLink icon="bar" name="Subscription Plan" prefixes="['create-plan','update-plan','view-plan','plan-list']"
                                         to="/list-subscription-plan"/>

                </div>
            </transition>


            <!--        settings-->
            <SidebarMenuItemLink icon="setting" name="Setting" prefixes="['setting']" to="/setting"
                                 @redirect="openSidebarDrop('')"/>
        </div>
    </div>
</template>

<script setup>

import SidebarMenuItemLink from "./SidebarMenuItemLink.vue";
import {ref} from "vue";

const sbarMenu = ref(localStorage.getItem("sidebarMenu"))
const openSidebarDrop = (value) => {
    if (sbarMenu.value == '') {
        localStorage.setItem("sidebarMenu", value);
        sbarMenu.value = localStorage.getItem("sidebarMenu");
    } else if (sbarMenu.value == value) {
        localStorage.setItem("sidebarMenu", '');
        sbarMenu.value = localStorage.getItem("sidebarMenu");
    } else {
        localStorage.setItem("sidebarMenu", value);
        sbarMenu.value = localStorage.getItem("sidebarMenu");
    }
}

const enter = (element) => {
    element.style.height = 'auto';
    const height = getComputedStyle(element).height;
    element.style.height = 0;
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
        element.style.height = height;
    });
}

const afterEnter = (element) => {
    element.style.height = 'auto';
}

const leave = (element) => {
    const height = getComputedStyle(element).height;
    element.style.height = height;
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
        element.style.height = 0;
    });
}

</script>

<style scoped>
.menuAccordion-enter-from, .menuAccordion-leave-to {
    height: 0;
}

.menuAccordion-enter-active, .menuAccordion-leave-active {
    transition: all 400ms ease;
}

#sidebar::-webkit-scrollbar {
    width: 5px;
    position: relative;
}

#sidebar::-webkit-scrollbar-thumb {
    background-color: rgb(153, 175, 227);
}

#sidebar::-webkit-scrollbar-track {
    background-color: rgb(91, 98, 115);
}
</style>
