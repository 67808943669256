<template>
    <div class="p-4">
        <div class="flex flex-wrap items-center justify-between pb-4">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="p-2 mt-6 rounded shadow-md bg-white">
            <p class="font-bold">Occupation Details</p>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 p-5">
                <div class="w-full flex items-center">
                    <label>Name:</label>
                    <p class="pl-2">{{ occupation.name }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label>Anzsco Code:</label>
                    <p class="pl-2">{{ occupation.anzsco_code }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label>ABS-Version:</label>
                    <p class="pl-2">{{ occupation.abs_version }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label>ABS-Link:</label>
                    <p class="pl-2">{{ occupation.abs_link }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label>Skill Level:</label>
                    <p class="pl-2">{{ occupation.skill_level }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label>Is Popular?:</label>
                    <p v-if="occupation.is_popular==1" class="pl-2">Yes</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
                <div class="w-full flex items-center">
                    <label>Status:</label>
                    <p v-if="occupation.status==1" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
                <div class="w-full">
                    <label>Short Description:</label>
                    <p v-html="occupation.short_description"></p>
                </div>
                <div class="w-full">
                    <label>Description:</label>
                    <p v-html="occupation.description"></p>
                </div>
            </div>
        </div>

        <div class="p-5 mt-6 rounded shadow-md bg-white">
            <div class="flex justify-between items-center">
                <p class="font-bold">Anzsco Group</p>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Group</th>
                    <th class="border border-gray-200 bg-gray-50 p-2">Code - Name</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="key in Object.entries(anzscoGroup)">
                    <td class="border border-gray-200 p-2">{{ key[0] }}</td>
                    <td class="border border-gray-200 p-2">
                        {{ anzscoGroup[key[0]] != null ? anzscoGroup[key[0]].code + ' - ' + anzscoGroup[key[0]].name : '' }}
                    </td>
                    <td class="border border-gray-200 p-2">
                        <p v-if="anzscoGroup[key[0]] != null && anzscoGroup[key[0]].status == 1" class="text-green-500">
                            Active</p>
                        <p v-else-if="anzscoGroup[key[0]] == null" class="text-orange-500">Not Available</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>


        <div class="p-5 mt-2 rounded shadow-md bg-white">
            <div class="flex justify-between items-center">
                <p class="font-bold">Assessing Authority</p>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Name</th>
                    <th class="border border-gray-200 bg-gray-50 p-2">Link</th>
                    <th class="border border-gray-200 bg-gray-50 p-2">Short Code</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="assessingAuth.length==0">
                    <td class="border border-gray-200 p-2 text-center " colspan="4">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in assessingAuth" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.name }}</td>
                    <td class="border border-gray-200 p-2">{{ data.link }}</td>
                    <td class="border border-gray-200 p-2">{{ data.short_code }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="p-5 mt-2 rounded shadow-md bg-white">
            <div class="flex justify-between items-center">
                <p class="font-bold">Occupation alternative</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'alternative', 'create', '')">Add New
                </button>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Title</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="alternativeList.length==0">
                    <td class="border border-gray-200 p-2 text-center" colspan="3">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in alternativeList" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.title }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div class="md:flex md:justify-center">
                            <svg class="w-5 h-5 mx-1 cursor-pointer"
                                 fill="none" stroke="Blue" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg" @click="openModal(true, 'alternative', 'update', data)">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <img alt="img" class="w-5 mx-1 cursor-pointer"
                                 src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid)">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="p-5 mt-6 rounded shadow-md bg-white">
            <div class="flex justify-between items-center">
                <p class="font-bold">Occupation specialization</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'specialization', 'create', '')">Add New
                </button>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Title</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="specializationList.length==0">
                    <td class="border border-gray-200 p-2 text-center" colspan="3">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in specializationList" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.title }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div class="md:flex md:justify-center">
                            <svg class="w-5 h-5 mx-1 cursor-pointer"
                                 fill="none" stroke="Blue" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg" @click="openModal(true, 'specialization', 'update', data)">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <img alt="img" class="w-5 mx-1 cursor-pointer"
                                 src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid)">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="p-5 mt-6 rounded shadow-md bg-white">
            <div class="flex justify-between items-center">
                <p class="font-bold">Occupation in NEC categoty</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'nec-category', 'create', '')">Add New
                </button>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Title</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="necCategoryList.length==0">
                    <td class="border border-gray-200 p-2 text-center" colspan="3">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in necCategoryList" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.title }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div class="md:flex md:justify-center">
                            <svg class="w-5 h-5 mx-1 cursor-pointer"
                                 fill="none" stroke="Blue" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg" @click="openModal(true, 'nec-category', 'update', data)">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <img alt="img" class="w-5 mx-1 cursor-pointer"
                                 src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid)">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, '', '', '')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 p-4">
                    <div class="w-full">
                        <label class="flex items-center"> Title <img alt="required" class="w-2 h-2 ml-1"
                                                                     src="@/assets/images/star.svg"> </label>
                        <input v-model='form.title'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1 focus:outline-none"
                               placeholder="Title" type='text'>
                        <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                            {{ form.errors().get('title') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <select v-model='form.status'
                                class="py-2.5 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.status==1" value="1">Active</option>
                            <option :selected="form.status==0" value="0">Inactive</option>
                        </select>
                    </div>
                </div>
                <div class="w-full px-4">
                    <label>Description</label>
                    <textarea v-model='form.description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1 focus:outline-none" cols="50" placeholder="Note"
                              rows="4"></textarea>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Classification'
            @close="closeDeleteModal"
            @submit="deleteOccupationClassification"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import Modal from '@/component/Modal'
import form from 'vuejs-form'
import DeleteModal from '@/component/DeleteModal'
import BackIcon from "../../../data/icons/BackIcon.vue";
import LoadingButton from "../../../component/LoadingButton.vue";

export default {
    components: {BackIcon, Breadcrumb, Modal, DeleteModal, LoadingButton},
    data() {
        return {
            breadcrumb: '',
            mdl_title: '',
            modal_mode: 'create',
            occupation_uuid: '',
            deletable_uuid: '',
            isDeleteSubmitting: false,
            isSubmitting: false,
            isModalSubmitting: false,
            is_open_delete_modal: false,

            occupation: {},
            assessingAuth: [],
            anzscoGroup: [],
            alternativeList: [],
            specializationList: [],
            necCategoryList: [],

            form: form.default({
                uuid: '',
                anzsco_code: '',
                type: '',
                title: '',
                description: '',
                status: '0',
            }).rules({
                title: 'required',
            }),

            importForm: form.default({file: '',}),

        };
    },

    methods: {
        openModal(value, type, mode, data) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && type == 'alternative') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'create') {
                    this.modal_mode = 'create';
                    this.mdl_title = 'Create Alternative';

                    this.form.uuid = '';
                    this.form.type = '2';
                    this.form.title = '';
                    this.form.description = '';
                    this.form.status = '0';
                } else {
                    this.modal_mode = 'update';
                    this.mdl_title = 'Update Alternative';
                    Object.assign(this.form, data);
                }
            } else if (value == true && type == 'specialization') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'create') {
                    this.modal_mode = 'create';
                    this.mdl_title = 'Create Specialization';

                    this.form.uuid = '';
                    this.form.type = '3';
                    this.form.title = '';
                    this.form.description = '';
                    this.form.status = '0';
                } else {
                    this.modal_mode = 'update';
                    this.mdl_title = 'Update Specialization';
                    Object.assign(this.form, data);
                }
            } else if (value == true && type == 'nec-category') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'create') {
                    this.modal_mode = 'create';
                    this.mdl_title = 'Create NEC Category';

                    this.form.uuid = '';
                    this.form.type = '4';
                    this.form.title = '';
                    this.form.description = '';
                    this.form.status = '0';
                } else {
                    this.modal_mode = 'update';
                    this.mdl_title = 'Update NEC Category';
                    Object.assign(this.form, data);
                }
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createData();
            } else {
                this.updateData();
            }
        },

        createData() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/occupation-classification/create", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.getSingleData();
                    this.openModal(false, '', '', '');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, '', '', '');
                });
            }
        },

        updateData() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/occupation-classification/update/" + this.form.uuid, this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.getSingleData();
                    this.openModal(false, '', '', '');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, '', '', '');
                });
            }
        },

        openDeleteModal(uuid) {
            this.deletable_uuid = uuid;
            this.is_open_delete_modal = true
        },

        closeDeleteModal() {
            this.deletable_uuid = '';
            this.is_open_delete_modal = false
            this.isDeleteSubmitting = false;
        },

        deleteOccupationClassification() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/occupation-classification/" + this.deletable_uuid).then((response) => {
                this.$toast.success(response.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
                this.getSingleData();
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        async getSingleData() {
            await VisaApi.get("/occupation/" + this.occupation_uuid).then((response) => {
                this.alternativeList = [];
                this.specializationList = [];
                this.necCategoryList = [];

                this.assessingAuth = response.data.assessing_authorities;
                this.anzscoGroup = response.data.anzsco_groups;

                this.occupation = response.data;
                this.form.anzsco_code = response.data.anzsco_code;
                if (response.data.classifications != null && response.data.classifications.length > 0) {
                    for (let i = 0; i < response.data.classifications.length; i++) {
                        if (response.data.classifications[i].type == '2') {
                            this.alternativeList.push(response.data.classifications[i]);
                        } else if (response.data.classifications[i].type == '3') {
                            this.specializationList.push(response.data.classifications[i]);
                        } else if (response.data.classifications[i].type == '4') {
                            this.necCategoryList.push(response.data.classifications[i]);
                        }
                    }
                }
            });
        },

        openImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.importForm.file = '';
        },

        handleDragOver(event) {
            event.preventDefault();
        },

        handleDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            this.importForm.file = file;
            // console.log('drag & drop file=', this.form.file);
        },

        handleFileSelected(e) {
            this.importForm.file = e.target.files[0];
            // console.log('browse file=',this.form.file);
        },

        formatFileSize(size) {
            if (size === 0) return "0 Bytes";
            const k = 1024;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
            const i = Math.floor(Math.log(size) / Math.log(k));
            return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
        },

        openFileInput() {
            this.$refs.fileInput.click();
        },

        removeImportFile() {
            this.importForm.file = '';
        },

        importExcelFile() {
            if (this.importForm.file == '') {
                this.$toast.error('Please select a file', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/occupation-classification/import", this.importForm.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImport();
                    this.importForm.file = '';
                    this.getSingleData();
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.occupation_uuid = this.$route.params.id;
        this.getSingleData();
    }
}
</script>
