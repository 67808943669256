<template>
    <div class="px-4 py-2">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/list-subscription-plan">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>
        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <!-- Plan Name -->
                <div class="w-full">
                    <label class="flex items-center">Name<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.name"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Plan Name"
                        type="text"
                    />
                    <span v-if="form.errors().has('name')" class="text-red-800 text-xs">
            {{ form.errors().get('name') }}
          </span>
                </div>

                <!-- Description -->
                <div class="w-full">
                    <label class="flex items-center">Description</label>
                    <textarea
                        v-model="form.description"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Description"
                    ></textarea>
                    <span v-if="form.errors().has('description')" class="text-red-800 text-xs">
            {{ form.errors().get('description') }}
          </span>
                </div>

                <!-- Is Active -->
                <div class="w-full">
                    <label class="flex items-center">Is Active</label>
                    <div class="flex items-center space-x-4">
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_active"
                                type="radio"
                                value="1"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Active</span>
                        </label>
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_active"
                                type="radio"
                                value="0"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Inactive</span>
                        </label>
                    </div>
                </div>

                <!-- Is Enterprise -->
                <div class="w-full">
                    <label class="flex items-center">Is Enterprise</label>
                    <div class="flex items-center space-x-4">
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_enterprise"
                                type="radio"
                                value="1"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Enterprise</span>
                        </label>
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_enterprise"
                                type="radio"
                                value="0"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Common</span>
                        </label>
                    </div>
                </div>

                <!-- Price -->
                <div class="w-full">
                    <label class="flex items-center">Price<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.price"
                        type="number"
                        step="0.01"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Price"
                    />
                    <span v-if="form.errors().has('price')" class="text-red-800 text-xs">
            {{ form.errors().get('price') }}
          </span>
                </div>

                <!-- Yearly Payment Discount Percentage -->
                <div class="w-full">
                    <label class="flex items-center">Yearly Payment Discount (%)<span class="text-red-500 ml-1">*</span></label>
                    <input v-model="form.yearly_payment_discount_percentage" type="number" min="0"
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                           placeholder="Discount Percentage"/>
                    <span v-if="form.errors().has('yearly_payment_discount_percentage')" class="error">{{ form.errors().get('yearly_payment_discount_percentage') }}</span>
                </div>

                <!-- Currency -->
                <div class="w-full">
                    <label class="flex items-center">Currency<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.currency"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Currency"
                        type="text"
                        maxlength="3"
                    />
                    <span v-if="form.errors().has('currency')" class="text-red-800 text-xs">
            {{ form.errors().get('currency') }}
          </span>
                </div>

                <!-- Invoice Period -->
                <div class="w-full">
                    <label class="flex items-center">Invoice Period<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.invoice_period"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Invoice Period"
                    />
                    <span v-if="form.errors().has('invoice_period')" class="text-red-800 text-xs">
        {{ form.errors().get('invoice_period') }}
    </span>
                </div>

                <!-- Invoice Interval -->
                <div class="w-full">
                    <label class="flex items-center">Invoice Interval<span class="text-red-500 ml-1">*</span></label>
                    <select
                        v-model="form.invoice_interval"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                    >
                        <option disabled selected>Please Select</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                    <span v-if="form.errors().has('invoice_interval')" class="text-red-800 text-xs">
        {{ form.errors().get('invoice_interval') }}
    </span>
                </div>


                <!-- Trial Period -->
                <div class="w-full">
                    <label class="flex items-center">Trial Period<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.trial_period"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Trial Period"
                    />
                    <span v-if="form.errors().has('trial_period')" class="text-red-800 text-xs">
            {{ form.errors().get('trial_period') }}
          </span>
                </div>

                <!-- Trial Interval -->
                <div class="w-full">
                    <label class="flex items-center">Trial Interval<span class="text-red-500 ml-1">*</span></label>
                    <select
                        v-model="form.trial_interval"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                    >
                        <option disabled selected>Please Select</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                    <span v-if="form.errors().has('trial_interval')" class="text-red-800 text-xs">
                        {{ form.errors().get('trial_interval') }}
                    </span>
                </div>

                <!-- Grace Period -->
                <div class="w-full">
                    <label class="flex items-center">Grace Period<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.grace_period"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Grace Period"
                    />
                    <span v-if="form.errors().has('grace_period')" class="text-red-800 text-xs">
            {{ form.errors().get('grace_period') }}
          </span>
                </div>

                <!-- Grace Interval -->
                <div class="w-full">
                    <label class="flex items-center">Grace Interval<span class="text-red-500 ml-1">*</span></label>
                    <select
                        v-model="form.grace_interval"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                    >
                        <option disabled selected>Please Select</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                    <span v-if="form.errors().has('grace_interval')" class="text-red-800 text-xs">
            {{ form.errors().get('grace_interval') }}
          </span>
                </div>

                <!-- Sort Order -->
                <div class="w-full">
                    <label class="flex items-center">Sort Order<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.sort_order"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Sort Order"
                    />
                    <span v-if="form.errors().has('sort_order')" class="text-red-800 text-xs">
            {{ form.errors().get('sort_order') }}
          </span>
                </div>
                <br>
            </div>

            <!-- Features -->
            <div class="mt-4">
                <hr>
                <FeatureListCreate :initialFeatures="form.features" @update-features="updateFeatures"/>
            </div>

            <!-- Submit Button -->
            <div class=" mt-4">
                <button
                    v-if="!isSubmitting"
                    @click="createPlan"
                    class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500"
                >
                    Submit
                </button>
                <span v-else class="block">
          <LoadingButton text="Submitting..."/>
        </span>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios";
import form from "vuejs-form";
import FeatureListCreate from "@/component/FeatureListCreate.vue";
import LoadingButton from "@/component/LoadingButton.vue";
import Breadcrumb from "../../../component/Breadcrumb.vue";
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb, FeatureListCreate, LoadingButton},
    data() {
        return {
            isSubmitting: false,
            breadcrumb: '',
            form: form.default({
                name: "",
                description: "",
                is_active: 0,
                is_enterprise: 0,
                price: 0,
                yearly_payment_discount_percentage: 0,
                currency: "",
                trial_period: 0,
                trial_interval: "",
                invoice_period: 0,
                invoice_interval: "",
                sort_order: 0,
                features: [],
            }).rules({
                name: "required",
                price: "required",
                currency: "required|string",
                trial_period: "required|integer",
                trial_interval: "required|string",
                invoice_period: "required|integer",
                invoice_interval: "required|string",
                sort_order: "required|integer",
                features: "required|array",
            }),
        };
    },
    methods: {
        updateFeatures({ id, value }) {
            const feature = { id: id.toString(), value };
            const index = this.form.features.findIndex(f => f.id === id.toString());

            if (index !== -1) {
                this.form.features[index] = feature;
            } else {
                this.form.features.push(feature);
            }

            console.log(JSON.parse(JSON.stringify(this.form.features)));
        },
        createPlan() {
            if (this.form.validate().errors().any()) {
                return;
            }
            console.log( JSON.parse(JSON.stringify(this.form.all())))
            this.isSubmitting = true;
            VisaApi.post("/subscription-plans", this.form.all())
                .then((response) => {
                    this.$toast.success("Plan created successfully", {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.$router.push({name: "list-subscription-plan"});
                    this.isSubmitting = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
        },
    },
};
</script>

<style scoped>
.visasis-card {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
