<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions to="/create-subscription-plan"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'is_active'">
        <span v-if="props.row.is_active" class="text-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
        </span>
                        <span v-else class="text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
        </span>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center gap-2">
                            <router-link :to="{ name: 'view-subscription-plan', params: {id:props.row.id} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name: 'edit-subscription-plan', params: {id:props.row.id} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon @click="openDeleteModal(props.row.id)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"/>
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_menu_delete_modal"
            modal_title='Delete Subscription Plan'
            @close="closeDeleteModal"
            @submit="deleteData"
        />
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TablePagination from '@/component/TablePagination'
import {AddIcon, DeleteIcon, UpdateIcon, ViewIcon} from "../../../data/icons";
import ToggleSwitch from "../../../component/ui/ToggleSwitch.vue";
import {meta_data, next_of_next_status, prev_of_prev_status,} from '@/libs/helpers.js'
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";

export default {
    name: "SubscriptionPlansList",
    components: {
        TableTopActions,
        TableEntryShow,
        DeleteModal,
        VueGoodTable,
        Breadcrumb,
        TablePagination,
        AddIcon,
        DeleteIcon,
        ViewIcon,
        UpdateIcon,
        ToggleSwitch
    },
    data() {
        return {
            id_deletable: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',
            is_open_menu_delete_modal: false,

            columns: [
                {label: 'Name', field: 'name'},
                {label: 'Currency', field: 'currency'},
                {label: 'Price', field: 'price'},
                {label: 'Discount Percentage', field: 'yearly_payment_discount_percentage'},
                {label: 'Active', field: 'is_active'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,
        };
    },

    methods: {
        async getList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code === 200) {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        openDeleteModal(id) {
            this.is_open_menu_delete_modal = true;
            this.id_deletable = id;
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_menu_delete_modal = false;
        },

        deleteData() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/subscription-plans/" + this.id_deletable).then((response) => {
                if (response.code == '200') {
                    this.getList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return this.url = '/subscription-plans?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.url = '/subscription-plans?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;;
        this.getList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
