<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/anzsco-details-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Select Type <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="form.type"
                        :options="anzsco_types"
                        :reduce="type => type.id"
                        class="pt-1"
                        label="name"
                        placeholder="Choose type"
                    />
                    <span v-if='form.errors().has("type")' class="text-red-800 text-xs">
                        {{ form.errors().get('type') }}
                    </span>
                </div>

                <div class="w-full">
                    <label class="flex items-center">Code <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.code'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Code" type='text'>
                    <span v-if='form.errors().has("code")' class="text-red-800 text-xs">
                        {{ form.errors().get('code') }}
                    </span>
                </div>
            </div>

            <div class="w-full mt-2">
                <label>Description</label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="form.description" :config="editorConfig" :editor="editor"/>
                </article>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="updateGroup">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import BackIcon from "../../../data/icons/BackIcon.vue";
import {anzsco_types} from "../../../data/type";
import ckeditorConfig from "../../../libs/ckeditorConfig";
import CKEditor from "@ckeditor/ckeditor5-vue";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect, ckeditor: CKEditor.component},
    data() {
        return {
            editorConfig: ckeditorConfig,
            breadcrumb: '',
            group_list: [],
            selected_group: [],
            isSubmitting: false,
            anzsco_types,

            form: form.default({
                id: '',
                uuid: '',
                code: '',
                description: '',
                type: '',
            }).rules({
                code: 'required',
                type: 'required',
            }),
        };
    },

    methods: {
        updateGroup() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/anzsco-details/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'anzsco-details-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        getSingleData(uuid) {
            VisaApi.get("/anzsco-details/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    this.form.type = Number(this.form.type)
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.id);
    }
}
</script>
