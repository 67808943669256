export const typeGroups = [
    {id: 1, name: 'Resident type'},
    {id: 2, name: 'Application type'},
    {id: 3, name: 'Sponsored type'},
]

export const getTypeGroup = (group) => {
    const type_group = typeGroups.find(item => item.id === group);
    if (type_group) return type_group.name
}

export const lin_sol_list = [
    {id: 1, name: "Medium And Long-Term Strategic Skills List", code: "MLTSSL"},
    {id: 2, name: "Short-Term Strategic List", code: "STSOL"},
    {id: 3, name: "Region List", code: "ROL"},
    {id: 4, name: "Core Skills Occupation List", code: "CSOL"},
]

export const anzsco_types = [
    {id: 1, name: 'Major'},
    {id: 2, name: 'Sub major'},
    {id: 3, name: 'Minor'},
    {id: 4, name: 'Unit'},
]

export const menu_item_links = [
    {id: 1, name: 'Home', link: '/'},
    {id: 2, name: 'About Us', link: '/about-us'},
    {id: 3, name: 'App Development', link: '/app-development'},
    {id: 4, name: 'Occupation', link: '/occupation'},
    {id: 5, name: 'Points Calculator', link: '/points-calculator'},
    {id: 6, name: 'Services', link: '/services'},
    {id: 7, name: 'Visa', link: '/visa'},
    {id: 8, name: 'Search', link: '/search'},
    {id: 9, name: 'Skill Authority', link: '/search/skill-authority'},
    {id: 10, name: 'Anzsco Group', link: '/search/anzsco-group'},
    {id: 11, name: 'State', link: '/search/state'},
]
