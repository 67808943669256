<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/list-subscription-plan">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="px-4 py-1 mb-2 font-bold rounded bg-gray-300 border-gray-400">
                Plan Name: {{ data.name }}
            </p>
            <div class="grid grid-cols-1 gap-2 p-4 w-full rounded bg-white">
                <div class="w-full flex items-center">
                    <label class="font-bold">Description:</label>
                    <p class="pl-2">{{ data.description }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Yearly Price:</label>
                    <p class="pl-2">{{ data.price }} {{ data.currency }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Discount Percentage:</label>
                    <p class="pl-2">{{ data.yearly_payment_discount_percentage }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Monthly Price:</label>
                    <p class="pl-2">{{ data.monthly_price }} {{ data.currency }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Trial Period:</label>
                    <p class="pl-2">{{ data.trial_period }} {{ data.trial_interval }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Invoice Period:</label>
                    <p class="pl-2">{{ data.invoice_period }} {{ data.invoice_interval }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Grace Period:</label>
                    <p class="pl-2">{{ data.grace_period }} {{ data.grace_interval }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Status:</label>
                    <p v-if="data.is_active" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Enterprise Plan:</label>
                    <p v-if="data.is_enterprise" class="pl-2">Yes</p>
                    <p v-else class="pl-2">No</p>
                </div>

                <div class="w-full">
                    <label class="font-bold">Features:</label>
                    <div class="grid grid-cols-2 gap-4 p-4 mt-2 bg-gray-100 rounded-lg shadow-md">
                        <div v-for="feature in data.features" :key="feature.id"
                             class="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition">
                            <div class="flex items-center space-x-2">
                                <span class="font-semibold text-gray-700">{{ feature.display_name }}</span>
                            </div>
                            <div>
                <span v-if="feature.type === 'bool'" class="font-medium">
                    {{ feature.pivot.value == true ? 'Yes' : 'No' }}
                </span>
                                <span v-else class="font-medium">{{ feature.pivot.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios";
import Breadcrumb from '@/component/Breadcrumb';
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb},
    data() {
        return {
            breadcrumb: '',
            data: {},
        };
    },

    methods: {
        async getSingleData(id) {
            try {
                const response = await VisaApi.get(`/subscription-plans/${id}`);
                if (response.code === 200) {
                    this.data = response.data;
                }
                console.log(this.data)
            } catch (error) {
                console.error("Error fetching data", error);
            }
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        const id = this.$route.params.id;
        this.getSingleData(id);
    }
};
</script>
