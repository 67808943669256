<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :need_another_import="true" :need_import="true" another_import_title="Classification"
                                 to="/occupation-create" @another_import="is_open_oc_import_modal = true"
                                 @import="is_open_import_modal = true"
                                 @input="search_input = $event"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'name'">
                        <p>{{ props.row.name }}</p>
                    </div>
                    <div v-if="props.column.field === 'description'">
                        <p class="whitespace-nowrap" v-html="props.row.description ? props.row.description.slice(0, 20) : ''"></p>
                    </div>
                    <div v-if="props.column.field === 'short_description'">
                        <p class="whitespace-nowrap" v-html="props.row.short_description ? props.row.short_description.slice(0, 20) : ''"></p>
                    </div>
                    <div v-if="props.column.field == 'is_popular'">
                        <p v-if="props.row.is_popular==1"
                           class="w-min px-2 text-left rounded m-auto text-white bg-green-700">Yes</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">No</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'occupation-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name: 'occupation-update', params: {id:props.row.uuid} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon
                                @click="[deletable_uuid=props.row.uuid, is_open_occupation_delete_modal = true]"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_occupation_delete_modal"
            modal_title='Delete Occupation'
            @close="[isDeleteSubmitting = false, is_open_occupation_delete_modal = false]"
            @submit="deleteOccupation"
        />

        <!-- Import -->
        <ImportModal
            :demo_link="demo_occupation_link"
            :is_open="is_open_import_modal"
            :is_submitting="isSubmitting"
            demo_link_label="Download Demo Occupation File"
            title="Import Occupation"
            @close="closeImportModal"
            @submit="importExcelFile"
        />

        <!-- Import -->
        <ImportModal
            :demo_link="demo_occupation_classification_link"
            :is_open="is_open_oc_import_modal"
            :is_submitting="isOcSubmitting"
            demo_link_label="Download Demo Occupation Classification File"
            title="Import Occupation Assessing Authority"
            @close="closeOcImportModal"
            @submit="importOcExcelFile"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import LoadingButton from "@/component/LoadingButton"
import form from 'vuejs-form'
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {DownloadIcon} from "../../../data/icons";
import ImportModal from "../../../component/ui/modals/ImportModal.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import AddIcon from "../../../data/icons/AddIcon.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import FormModal from "../../../component/ui/modals/FormModal.vue";
import {ClassicEditor} from 'ckeditor5';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ckeditorConfig from "../../../libs/ckeditorConfig";

export default {
    components: {
        FormModal,
        TableEntryShow,
        TableTopActions,
        AddIcon,
        ViewIcon,
        UpdateIcon,
        DeleteIcon,
        ckeditor: CKEditor.component,
        ImportModal, DownloadIcon, DeleteModal, Breadcrumb, TablePagination, LoadingButton, VueGoodTable
    },
    data() {
        return {
            deletable_uuid: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',
            demo_occupation_link: process.env.VUE_APP_ASSET_FILE_URL + '/demo_occupation.xlsx',

            search_input: '',
            breadcrumb: '',
            sort_status: false,
            isSubmitting: false,
            isDeleteSubmitting: false,
            is_open_import_modal: false,
            is_open_occupation_delete_modal: false,
            is_open_oc_import_modal: false,
            isOcSubmitting: false,
            demo_occupation_classification_link: process.env.VUE_APP_ASSET_FILE_URL + "/demo_classification.xlsx",
            mdl_title: "Occupation Description",
            is_open_details_modal: false,
            isDetailsModalSubmitting: false,
            editor: ClassicEditor,
            editorConfig: ckeditorConfig,
            form: form.default({
                file: '',
            }).rules({
                file: 'required',
            }),

            columns: [
                {label: 'Code', field: 'anzsco_code'},
                {label: 'Name', field: 'name'},
                {label: 'Description', field: 'description'},
                {label: 'Short Description', field: 'short_description'},
                {label: 'Skill Level', field: 'skill_level'},
                {label: 'Is Popular?(6)', field: 'is_popular', sortable: false, width: '200px'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
        };
    },

    methods: {
        async getList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    if (response.data.meta.to > 50) {
                        this.meta.per_page = 'All'
                    } else {
                        Object.assign(this.meta, response.data.meta);
                    }
                    this.rows = response.data.data;
                }
            });
        },

        deleteOccupation() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/occupation/" + this.deletable_uuid).then((response) => {
                if (response.success == true) {
                    this.rows = this.rows.filter(item => item.uuid !== this.deletable_uuid);
                    this.meta.total -= 1;
                    this.meta.to -= 1;
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isDeleteSubmitting = false;
                    this.is_open_occupation_delete_modal = false;
                }
            }).catch((error) => {
                this.isDeleteSubmitting = false;
                this.is_open_occupation_delete_modal = false;
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
            });
        },

        closeImportModal() {
            this.isSubmitting = false
            this.is_open_import_modal = false
        },

        importExcelFile(file) {
            if (file == '') {
                this.$toast.error('No file selected!', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                VisaApi.post("/occupation/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.closeImportModal()
                        this.getList();
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImportModal()
                });
            }
        },

        closeOcImportModal() {
            this.isOcSubmitting = false
            this.is_open_oc_import_modal = false
        },

        importOcExcelFile(file) {
            if (file == '') {
                this.$toast.error('No file selected!', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                VisaApi.post("/occupation-classification/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeOcImportModal();
                    this.getList()
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeOcImportModal()
                });
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        }
    },

    computed: {
        apiUrl() {
            return 'occupations?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=anzsco_code&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.getList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
