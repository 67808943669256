<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :action_title="'Assign'" :is_modal="true" :need_button="true"
                                 @action="router().push({'name':'visa-visa-condition-list'})" @create="openModal(true, 'create')"
                                 @input="search_input = $event"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'description'">
                        <span v-if="props.row.description!=null && props.row.description.length >= 30"
                              class="whitespace-nowrap"> {{ props.row.description.slice(0, 30) }} ... </span>
                        <span v-else class="whitespace-nowrap"> {{ props.row.description }} </span>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'visa-condition-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <update-icon @click="getSingleVisaCondition(props.row.uuid)"/>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Condition Number <img alt="required"
                                                                               class="w-2 h-2 ml-1"
                                                                               src="@/assets/images/star.svg"> </label>
                        <input v-model='form.con_number' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="condition number"
                               type='text'>
                        <span v-if='form.errors().has("con_number")' class="text-red-800 text-xs">
                            {{ form.errors().get('con_number') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Schedule Number <img alt="required"
                                                                              class="w-2 h-2 ml-1"
                                                                              src="@/assets/images/star.svg"> </label>
                        <input v-model='form.schedule_number' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="schedule number"
                               type='text'>
                        <span v-if='form.errors().has("schedule_number")' class="text-red-800 text-xs">
                            {{ form.errors().get('schedule_number') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <select v-model='form.status'
                                class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.status==1" value="1">Active</option>
                            <option :selected="form.status==0" value="0">Inactive</option>
                        </select>
                    </div>
                </div>
                <div class="w-full p-2">
                    <label>Description</label>
                    <ckeditor v-model="form.description" :config="editorConfig" :editor="editor"/>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Visa Condition'
            @close="closeDeleteModal"
            @submit="deleteVisaCondition"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import AddIcon from "../../../data/icons/AddIcon.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import router from "../../../router";
import CKEditor from "@ckeditor/ckeditor5-vue"
import ckeditorConfig from "../../../libs/ckeditorConfig";
import {ClassicEditor} from "ckeditor5";

export default {
    components: {
        TableTopActions, TableEntryShow,
        DeleteIcon,
        UpdateIcon,
        ViewIcon, AddIcon, Modal, DeleteModal, VueGoodTable, Breadcrumb, "v-select": vSelect, TablePagination, ckeditor: CKEditor.component
    },
    data() {
        return {
            mdl_title: 'Create Visa Condition',
            modal_mode: 'create',
            uuid_deletable: '',
            editor: ClassicEditor,
            editorConfig: ckeditorConfig,
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            columns: [
                {label: 'Condition Number', field: 'con_number'},
                {label: 'Schedule Number', field: 'schedule_number'},
                {label: 'Description', field: 'description'},
                {label: 'Status', field: 'status'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            is_open_delete_modal: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,

            form: form.default({
                id: '',
                uuid: '',
                con_number: '',
                schedule_number: '',
                description: '',
                status: '1',
            }).rules({
                con_number: 'required',
                schedule_number: 'required',
            }).messages({
                'con_number.required': 'This field is required.',
            }),
        };
    },

    methods: {
        router() {
            return router
        },
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.id = '';
                this.form.uuid = '';
                this.form.con_number = '';
                this.form.schedule_number = '';
                this.form.description = '';
                this.form.status = '1';

                this.modal_mode = 'create';
                this.mdl_title = 'Create Visa Condition';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        createVisaCondition() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.openModal(false, 'create');
                VisaApi.post("/visa-condition/create", this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.getVisaConditionList();
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        async getVisaConditionList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        async getSingleVisaCondition(uuid) {
            await VisaApi.get("/visa-condition/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    this.mdl_title = 'Update Visa Condition';
                    this.modal_mode = 'update';
                    this.openModal(true, 'update');
                }
            });
        },

        updateVisaCondition() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/visa-condition/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.openModal(false, 'update');
                        this.getVisaConditionList()
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        openDeleteModal(uuid) {
            this.is_open_delete_modal = true
            this.uuid_deletable = uuid;
        },

        closeDeleteModal() {
            this.is_open_delete_modal = false
            this.isDeleteSubmitting = false;
        },

        deleteVisaCondition() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/visa-condition/" + this.uuid_deletable).then((response) => {
                if (response.code == '200') {
                    this.getVisaConditionList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createVisaCondition();
            } else {
                this.updateVisaCondition();
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'visa-condition?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=schedule_number&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getVisaConditionList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.url = 'visa-condition?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=schedule_number&order_by_type=desc&search=' + this.search_input;
        this.getVisaConditionList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
