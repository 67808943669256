<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/list-user">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Name <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model="form.name" class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Name"
                           type="text">
                    <span v-if="form.errors().has('name')" class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Email <img alt="required" class="w-2 h-2 ml-1"
                                                                src="@/assets/images/star.svg"> </label>
                    <input v-model="form.email" class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Email"
                           type="email">
                    <span v-if="form.errors().has('email')" class="text-red-800 text-xs">
                        {{ form.errors().get('email') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Password</label>
                    <input v-model="form.password" class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Password"
                           type="password">
                    <span v-if="form.errors().has('password')" class="text-red-800 text-xs">
                        {{ form.errors().get('password') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Confirm Password</label>
                    <input v-model="form.password_confirmation" class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Confirm Password"
                           type="password">
                </div>
                <div class="w-full">
                    <label>Role</label>
                    <v-select
                        v-model="form.role_id"
                        :options="roles"
                        label="name"
                        :reduce="role => role.id"
                        placeholder="Select Role"
                        class="pt-1"
                    />
                    <span v-if="form.errors().has('role_id')" class="text-red-800 text-xs">
                        {{ form.errors().get('role_id') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Active Status</label>
                    <select v-model="form.active_status" class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
            </div>

            <button
                :disabled="isSubmitting"
                class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500"
                @click="update"
            >
                <span v-if="isSubmitting">Updating...</span>
                <span v-else>Update</span>
            </button>
        </div>
    </div>
</template>

<script>
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import BackIcon from "../../../data/icons/BackIcon.vue";
import VisaApi from "@/libs/axios"

export default {
    name: "UpdateUser",
    components: {BackIcon, Breadcrumb, "v-select": vSelect},

    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            roles: [
                { id: 1, name: 'Super Admin' },
                { id: 2, name: 'Admin' },
                { id: 3, name: 'Agent' },
                { id: 4, name: 'User' },
            ],

            form: form.default({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                role_id: null,
                active_status: '1',
            }).rules({
                name: 'required',
                email: 'required|email',
                role_id: 'required',
                active_status: 'required',
            }),
        };
    },

    methods: {
        update() {
            if (this.form.validate().errors().any()) {
                return;
            }
            this.isSubmitting = true;
            VisaApi.put(`user-management/users/${this.$route.params.id}`, this.form.all())
                .then(response => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({ name: 'list-user' });
                    }
                })
                .catch(error => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },

        fetchUser() {
            VisaApi.get(`user-management/users/${this.$route.params.id}`)
                .then(response => {
                    if (response.code == '200') {
                        this.form.name = response.data.name;
                        this.form.email = response.data.email;
                        this.form.role_id = response.data.role.id;
                        this.form.active_status = response.data.active_status.toString();
                    }
                })
                .catch(error => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.fetchUser();
    },
};
</script>