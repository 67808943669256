<script>
export default {
    name: "ViewUser"
}
</script>

<template>
<div>
    <h1>Hello</h1>
</div>
</template>

<style scoped>

</style>